@media screen and (max-width: 540px) {
  .MuiTableHead-root {
    display: none !important;
  }

  .MuiTableBody-root {
    background-color: #eee;
  }

  .MuiTableRow-root {
    background-color: #fff;
    border-bottom: none !important;
    border-radius: 8px;
    display: flex !important;
    flex-flow: column nowrap;
    margin: 24px;
  }

  .MuiTableCell-root {
    width: 100% !important;
  }

  .MuiTableCell-root:last-of-type {
    border: none;
  }
}
